import Accordion from 'components/Accordion/Accordion';
import Attachment from 'components/Attachment/Attachment';
import Button from 'components/Button/Button';
import ButtonsWithIntro from 'components/ButtonsWithIntro/ButtonsWithIntro';
import Card from 'components/Card/Card';
import Checkbox from 'components/Checkbox/Checkbox';
import Confirm from 'components/Confirm/Confirm';
import Dropdown from 'components/Dropdown/Dropdown';
import Form from 'components/Form/Form';
import FullModal from 'components/FullModal/FullModal';
import Heading from 'components/Heading/Heading';
import Icon from 'components/Icon/Icon';
import Image from 'components/Image/Image';
import Input from 'components/Input/Input';
import Label from 'components/Label/Label';
import List from 'components/List/List';
import Loader from 'components/Loader/Loader';
import Menu from 'components/Menu/Menu';
import Message from 'components/Message/Message';
import Modal from 'components/Modal/Modal';
import PlaceHolder from 'components/PlaceHolder/PlaceHolder';
import Popup from 'components/Popup/Popup';
import Progress from 'components/Progress/Progress';
import Radio from 'components/Radio/Radio';
import RangeSlider from 'components/RangeSlider/RangeSlider';
import SlideLoader from 'components/SlideLoader/SlideLoader';
import Tab from 'components/Tab/Tab';
import Table from 'components/Table/Table';
import TextArea from 'components/TextArea/TextArea';

import { Grid, Row, Col } from 'react-flexbox-grid';
import FindExpert from 'components/FindExpert/FindExpert';
import { FindExpertSubscribes, FindExpertSubject } from 'components/FindExpert/FindExpert.subject';

import 'assets/css/semantic.min.css'
import 'global-styles/overrides.scss';
import packJson from '../package.json';

window.bcgShared = window.bcgShared || [];
window.bcgShared.push(`${packJson.name} ${packJson.version}`);


export {
  Accordion,
  Attachment,
  Button,
  ButtonsWithIntro,
  Card,
  Checkbox,
  Col,
  Confirm,
  Dropdown,
  FindExpert,
  FindExpertSubject,
  FindExpertSubscribes,
  Form,
  FullModal,
  Grid,
  Heading,
  Icon,
  Image,
  Input,
  Label,
  List,
  Loader,
  Menu,
  Message,
  Modal,
  PlaceHolder,
  Popup,
  Progress,
  Radio,
  RangeSlider,
  Row,
  SlideLoader,
  Tab,
  Table,
  TextArea
};